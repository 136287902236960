@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans,
    Helvetica Neue, sans-serif;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

@layer components {
  .t-container {
    @apply text-lg max-w-prose mx-auto;
  }
  .t-span {
    @apply block text-base text-center text-indigo-600 font-semibold tracking-wide uppercase;
  }
  .t-h1 {
    @apply mt-2 block text-3xl text-center leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl;
  }
  .t-p-container {
    @apply prose prose-indigo prose-lg text-gray-500 mx-auto;
  }
  .t-p {
    @apply mt-3 text-xl text-gray-500 leading-8;
  }

  .btn-full {
    @apply shadow w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 md:py-4 md:text-lg md:px-10;
  }
  .btn-light {
    @apply w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-200 md:py-4 md:text-lg md:px-10;
  }
}
